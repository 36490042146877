export const query_User = gql`
  query User {
    User {
      ID
      name
    }
  }
`;

export const query_Signup = gql`
  mutation Login($params: String!) {
    Signup(params: $params)
  }
`;

export const query_Login = gql`
  mutation Login($email: String!, $password: String!) {
    Login(email: $email, password: $password)
  }
`;

export const query_IsPartOfProject = gql`
  mutation IsPartOfProject($projectID: String!) {
    IsPartOfProject(projectID: $projectID)
  }
`

export const query_RequestPasswordRecovery = gql`
  mutation RequestPasswordRecovery($email: String!) {
    RequestPasswordRecovery(email: $email)
  }
`;

export const query_ValidatePasswordRecovery = gql`
  mutation ValidatePasswordRecovery($token: String!, $password: String!) {
    ValidatePasswordRecovery(token: $token, password: $password)
  }
`;

export const query_Logout = gql`
  mutation Logout {
    Logout
  }
`;
